class Gallery {
  constructor(options) {

    var defaults = {
      title: '',
      link: '',
      adSlot: '',
      containerSelector: '.story-photo-gallery',
      modalSelector: '#modal',
      imageSelector: 'img',
      captionSelector: '.photo-description',
      indexSelector: '.photo-num',
      previousButtonSelector: '.previous-button',
      nextButtonSelector: '.next-button',
      closeButtonSelector: '.close-modal',
      triggerSelector: '.story-photo-gallery .slides-nav a',
      previewSelector: '.modal-open'
    };

    this.params = Object.assign({}, defaults, options || {});

    this.modal = document.querySelector(this.params.modalSelector);
    this.image = this.modal.querySelector(this.params.imageSelector);
    this.caption = this.modal.querySelector(this.params.captionSelector);
    this.index = this.modal.querySelector(this.params.indexSelector);
    this.previousButton = this.modal.querySelector(this.params.previousButtonSelector);
    this.nextButton = this.modal.querySelector(this.params.nextButtonSelector);
    this.closeButton = this.modal.querySelector(this.params.closeButtonSelector);
    this.container = document.querySelector(this.params.containerSelector);
    this.triggers = this.container.querySelectorAll(this.params.triggerSelector);
    this.previewButton = this.container.querySelector(this.params.previewSelector);

    this.images = this.params.images;
    this.active = 0;
    this.total = this.images.length;
    this.state = 0;
    this.scroll = 0;

    this.addEvents();
  }

  addEvents() {
    window.addEventListener('keydown', (event) => this.onKeyDown(event));
    window.addEventListener('scroll', (event) => this.onScroll(event));
    if (this.previewButton) {
      this.previewButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.active = 0;
        this.show();
        this.update();
      });
    }
    if (this.closeButton) {
      this.closeButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.hide();
      });
    }
    if (this.previousButton) {
      this.previousButton.addEventListener('click', (event) => this.previous(event, 'previous'));
    }
    if (this.nextButton) {
      this.nextButton.addEventListener('click', (event) => this.next(event, 'next'));
    }
    for (var index = 0; index < this.triggers.length; index++) {
      this.addTriggerEvent(index);
    }
  }

  addTriggerEvent(index) {
    this.triggers[index].addEventListener('click', (event) => {
      event.preventDefault();
      this.active = index;
      this.show();
      this.update();
    });
  }

  show() {
    this.modal.classList.add('visible-modal');
    this.state = 1;
    this.scroll = window.pageYOffset;
  }

  hide() {
    this.modal.classList.remove('visible-modal');
    this.state = 0;
    this.reset();
  }

  reset() {
    this.image.src = '';
    this.image.alt = '';
    this.caption.innerHTML = '';
  }

  onKeyDown(event) {
    if (!this.state) {
      return;
    }
    if (event.keyCode == 37) {
      this.previous();
    } else if (event.keyCode == 39) {
      this.next();
    } else if (event.keyCode == 27) {
      this.hide();
    }
  }

  onScroll(event) {
    if (this.state) {
      window.scrollTo(0, this.scroll);
    }
  }

  next() {
    if (!this.state) {
      return;
    }
    if (this.active + 1 >= this.total) {
      this.active = 0;
    } else {
      this.active++;
    }
    this.update();
  }

  previous() {
    if (!this.state) {
      return;
    }
    if (this.active - 1 < 0) {
      this.active = this.total - 1;
    } else {
      this.active--;
    }
    this.update();
  }

  update() {
    var active = this.images[this.active];
    this.image.src = active.img_main;
    this.image.alt = active.title;
    let html = active.description;
    if (active.source) {
      html += '<cite>' + active.source + '</cite>';
    }
    this.caption.innerHTML = html;
    let num = this.active + 1;
    this.index.textContent = num < 10 ? '0' + num : num;
    this.trackPageView();
    this.showAd();
  }

  showAd() {
    if (typeof googletag === 'undefined') {
      return;
    }
    if (!window.newsGalleryAdSlot) {
      googletag.cmd.push(() => {
        window.newsGalleryAdSlot = googletag.defineSlot(this.params.adSlot, [
          [300, 250],
          [300, 600]
        ], 'gallery-banner').addService(googletag.pubads());
      });
      googletag.display('gallery-banner');
    } else {
      googletag.pubads().refresh([window.newsGalleryAdSlot]);
    }
  }

  trackPageView() {
    if (window.ga) {
      ga('set', {
        page: this.params.link,
        title: this.params.title
      });
      ga('send', 'pageview');
    }

    if (window.AT_hit) {
      window.AT_hit.sendTag("F");
    }
  }
}

export {
  Gallery as
  default
};
