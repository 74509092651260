class Tabs {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.container = this.params.container;
    this.equalHeights = this.container.dataset.equalheights;
    this.links = this.container.querySelectorAll('.tab-link');
    this.blocks = this.container.querySelectorAll('.tab-content');
    this.active = 0;
    this.start();
  }

  start() {
    if (this.equalHeights) {
      window.setTimeout(() => {
        this.setEqualHeights();
      }, 800);
    }
    this.update();
    this.links.forEach((link, index) => {
      this.addEvent(link, index);
    });
  }

  setEqualHeights() {
    let max = 0;
    this.blocks.forEach((block) => {
      let blockHeight = parseInt(block.offsetHeight);
      if (blockHeight > max) {
        max = blockHeight;
      }
    });
    this.blocks.forEach((block) => {
      block.style.height = max + 'px';
    });
  }

  addEvent(link, index) {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      this.active = index;
      this.update();
    });
  }

  update() {
    for (var i = 0; i < this.blocks.length; i++) {
      this.blocks[i].style.display = 'none';
    }
    this.blocks[this.active].style.display = 'block';

    for (var y = 0; y < this.blocks.length; y++) {
      this.links[y].classList.remove('active');
    }
    this.links[this.active].classList.add('active');
  }

}

export {
  Tabs as
  default
};
