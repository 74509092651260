import Tabs from './tabs.js';

class Global {
  constructor(options) {
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.run();
  }

  run() {
    this.tabs();
    this.menu();
  }


  tabs() {
    var i, tabs = document.querySelectorAll('.tabs');
    for (i = 0; i < tabs.length; i++) {
      new Tabs({container:tabs[i]});
    }
  }

  menu() {
    var toggler = document.querySelector('.burger');
    if (toggler) {
      toggler.addEventListener('click', function(event) {
        event.preventDefault();
        document.body.classList.toggle('menu-opened');
      });
    }
  }
}

export {
  Global as
  default
};
